import { useUiStateStore } from '@/@core/services/uiStateStore';
import { reactive, toRefs } from '@vue/composition-api';
import { useUserStore } from '../../services/store';
import { useDistrictStore } from '@/modules/district/services/store';

type UserModelState = {
  headers: any[];
  isLoading: boolean;
  showFormDialog: boolean;
  showDeleteDialog: boolean;
  isFormValid: boolean;
  formData: any;
  search: string;
};

const state = reactive<UserModelState>({
  headers: [
    {
      text: 'Username / NPWP',
      value: 'username'
    },
    {
      text: 'Role',
      value: 'role.alias'
    },
    {
      text: 'Kecamatan',
      value: 'district.name'
    },
    {
      text: '',
      value: 'actions',
      sortable: false,
      width: '1%'
    }
  ],
  isLoading: false,
  showFormDialog: false,
  showDeleteDialog: false,
  isFormValid: false,
  formData: {
    password: ''
  },
  search: ''
});

export default function useUserModel() {
  const store = useUserStore();
  const districtStore = useDistrictStore();

  const useFindRole = (userDto: any) => {
    const roles = store.roles as unknown as any[];
    const findRole = roles.find(
      (role: any) => role.alias == userDto.role
    );

    if (!findRole) {
      useUiStateStore().createSnackbar({
        message: `Role ${userDto.role} tidak ditemukan.`
      });

      return;
    }

    return findRole;
  };

  const useFindDistrict = (userDto: any) => {
    const districts = districtStore.districts as unknown as any[];
    const districtName = userDto.district?.name || userDto.district;
    const findDistrict = districts.find(
      (district: any) => districtName === district.name
    );

    if (!findDistrict) {
      useUiStateStore().createSnackbar({
        message: `District ${userDto.district} tidak ditemukan.`
      });

      return;
    }

    return findDistrict;
  };

  const onGetManyUser = async () => {
    state.isLoading = true;

    try {
      state.isLoading = true;
      await store.getManyUser();
    } catch (err: any) {
      // useErrorMessageHandler(err)
    } finally {
      state.isLoading = false;
    }
  };

  const onCreateUser = async (data: any) => {
    state.isLoading = true;

    try {
      const findRole = useFindRole(data);
      if (findRole) data.role = findRole;

      const findDistrict = useFindDistrict(data);
      if (findDistrict) data.district = findDistrict;

      await store.postUser(data);
      useUiStateStore().createSnackbar({
        message: `User ${data.username} berhasil ditambahkan.`
      });

      state.showFormDialog = false;
      await onGetManyUser();
      state.formData = null;
    } catch (err: any) {
      // useErrorMessageHandler(err)
    } finally {
      state.isLoading = false;
    }
  };

  const onUpdateUser = async (data: any) => {
    state.isLoading = true;

    try {
      const findRole = useFindRole(data);
      if (findRole) data.role = findRole;

      const findDistrict = useFindDistrict(data);
      if (findDistrict) data.district = findDistrict;

      await store.putUser(data);
      useUiStateStore().createSnackbar({
        message: `User ${data.username} berhasil diubah.`
      });
      state.showFormDialog = false;
      await onGetManyUser();
      state.formData = null;
    } catch (err: any) {
      // useErrorMessageHandler(err)
    } finally {
      state.isLoading = false;
    }
  };

  const createUser = () => {
    state.formData = null;
    state.showFormDialog = true;
  };

  const editUser = (user: any) => {
    state.formData = { ...user };
    state.showFormDialog = true;
  };

  const deleteUser = (user: any) => {
    state.formData = user;
    state.showDeleteDialog = true;
  };

  const onDeleteUser = async () => {
    state.isLoading = true;

    try {
      await store.deleteUser(state.formData);
      const data: any = state.formData || {};
      useUiStateStore().createSnackbar({
        message: `User ${data.username} berhasil dihapus.`
      });
      state.showDeleteDialog = false;
      state.formData = null;
      await onGetManyUser();
    } catch (err: any) {
      // useErrorMessageHandler(err)
    } finally {
      state.isLoading = false;
    }
  };

  const onSubmitForm = () => {
    const isEdit = (state.formData as any)?.id;

    if (isEdit) onUpdateUser(state.formData);
    else onCreateUser(state.formData);
  };

  return {
    ...toRefs(state),
    store,
    onGetManyUser,
    onSubmitForm,
    onDeleteUser,
    deleteUser,
    editUser,
    createUser,
    useFindDistrict
  };
}

export { useUserModel };
