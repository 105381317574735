import useErrorMessageHandler from "@/@core/helpers/errorMessageHandler"
import { useCallApi } from "@/@core/services/axiosCall"
import { reactive, Ref, ref, toRefs, unref } from "@vue/composition-api"
import { defineStore } from "pinia"

export const useUserStore = defineStore('user', () => {
    const state = reactive({
        total: 0,
        users: [],
        roles: []
    })

    async function getManyRole() {
        const {
            errorMessage,
            result,
            error
        } = await useCallApi({
            method: 'get',
            url: '/roles',
        })

        if (errorMessage) {
            useErrorMessageHandler(error)

            return null
        }

        state.roles = result.roles

        return result
    }


    async function getManyUser() {
        const {
            errorMessage,
            result,
            error
        } = await useCallApi({
            method: 'get',
            url: '/users',
        })

        if (errorMessage) {
            useErrorMessageHandler(error)

            return null
        }

        state.users = result.users
        state.total = result.count

        return result
    }

    async function postUser(data: any) {
        const {
            errorMessage,
            result,
            error
        } = await useCallApi({
            method: 'post',
            url: '/users',
            data: unref(data)
        })

        if (errorMessage) {
            useErrorMessageHandler(error)

            return null
        }

        return result
    }

    async function putUser(data: any) {
        const {
            errorMessage,
            result,
            error
        } = await useCallApi({
            method: 'put',
            url: '/users/' + data.id,
            data: unref(data)
        })

        if (errorMessage) {
            useErrorMessageHandler(error)

            return null
        }

        return result
    }

    async function deleteUser(data: any) {
        const {
            errorMessage,
            result,
            error
        } = await useCallApi({
            method: 'delete',
            url: '/users/' + data.id,
        })

        if (errorMessage) {
            useErrorMessageHandler(error)

            return null
        }

        return result
    }
    return {
        ...toRefs(state),
        getManyRole,
        getManyUser,
        postUser,
        deleteUser,
        putUser
    }
})
