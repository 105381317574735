import useUserModel from '@/modules/user/pages/default-page/model';

const valueCheck = (value: any) => typeof value == 'string';

const sanitezeNumber = (value: string) => {
  const isNumber = typeof value == 'number';

  if (!isNumber) return 0;

  const removeComma = String(value).replaceAll(',', '');
  const removeDot = removeComma.replaceAll('.', '');
  const sanitezedNumber = Number(removeDot);

  return sanitezedNumber;
};

const sanitezeNumberString = (value: string) => {
  const isNumber = typeof value == 'string';

  if (!isNumber) return 0;

  const removeComma = String(value).replaceAll(',', '');
  const removeDot = removeComma.replaceAll('.', '');
  const sanitezedNumber = Number(removeDot);

  return sanitezedNumber;
};

export const name = (value: string) =>
  valueCheck(value) ? value : undefined;

export const address = (value: string) =>
  valueCheck(value) ? value : undefined;

export const geolocation = (value: string) => {
  if (!valueCheck(value)) return undefined;

  const sanitezed = value.replaceAll(' ', '');
  const splittedGeolocation = sanitezed.split(',');

  return {
    latitude: splittedGeolocation[0],
    longitude: splittedGeolocation[1]
  };
};

export const npwp = (value: string) =>
  valueCheck(value) ? value : undefined;

export const inChargeName = (value: string) =>
  valueCheck(value) ? value : undefined;

export const ownerName = (value: string) =>
  valueCheck(value) ? value : undefined;

export const businessEntity = (value: string) => {
  if (!valueCheck(value)) return undefined;

  let val = '-';
  const allowedValue = ['PT', 'CV', 'Koperasi', 'Yayasan', 'PO'];

  if (allowedValue.includes(value)) val = value;

  return val;
};

export const phoneNumber = (value: string) => {
  if (!valueCheck(value)) return '';

  const removedDash = value.replaceAll('-', '');
  const removedSpace = removedDash.replaceAll(' ', '');

  return removedSpace;
};

export const classification = (value: string) => {
  if (!valueCheck(value)) return undefined;

  const [kbliCode] = value.split(' ');

  return kbliCode;
};

export const permissions = (value: string) => {
  if (!valueCheck(value) || value == '-') return [];

  const formattedPermissions: any[] = [];
  const splittedPermissions = value.split('#');

  splittedPermissions.map((p) => {
    const splittedPermission = p.split(',');

    if (splittedPermission.length < 2) return;

    let date = splittedPermission[2].split('-');
    date = date.map((d) => d.trim());

    // Check if date format is reversed
    // reverse date if first element not a year
    if (date[0].length != 4) date.reverse();

    const permission = {
      type: splittedPermission[0].trim() ?? '-',
      value: splittedPermission[1].trim() ?? '-',
      date: date.join('-') ?? '-',
      agency: splittedPermission[3].trim() ?? '-'
    };

    formattedPermissions.push(permission);
  });

  return formattedPermissions;
};

export const socialMedias = (value: string) => {
  if (!valueCheck(value)) return [];

  const formattedSocialMedias: any[] = [];
  const splittedSocialMedias = value.split('#');

  splittedSocialMedias.map((s) => {
    const sanitezed = s.replaceAll(' ', '');
    const [socialMediaType, socialMediaAccount] =
      sanitezed.split(':');

    let type;
    if (socialMediaType.toLowerCase() == 'fb') type = 'facebook';
    if (socialMediaType.toLowerCase() == 'ig') type = 'instagram';
    if (socialMediaType.toLowerCase() == 'email') type = 'email';
    if (socialMediaType.toLowerCase() == 'tokopedia')
      type = 'tokopedia';
    if (socialMediaType.toLowerCase() == 'shopee') type = 'shopee';

    if (!type) return;

    const socialMedia = {
      type,
      value: socialMediaAccount
    };

    formattedSocialMedias.push(socialMedia);
  });

  return formattedSocialMedias;
};

export const employees = ({ male, female }: any) => {
  return {
    male: male ?? null,
    female: female ?? null
  };
};

export const totalInvestmentValue = (value: string) => {
  const sanitezedValue = sanitezeNumber(value);

  return sanitezedValue ?? undefined;
};

export const totalCostsPerMonth = (value: string) => {
  const sanitezedValue = sanitezeNumber(value);
  return sanitezedValue / 12 ?? undefined;
};

export const tags = (value: string) =>
  valueCheck(value) ? value.split('#') : undefined;

export const products = ({
  names,
  capacityPerYears,
  units,
  pricePerUnits
}: any) => {
  if (!names || !capacityPerYears || !units || !pricePerUnits)
    return [];

  const sanitizeValue = (value: string) => {
    let removeNewLine = value.replaceAll('\n', '');
    let removeSpace = removeNewLine.replaceAll(' ', '');

    return removeSpace;
  };

  const splittedNames = names.replaceAll('\n', '').trim().split('#');
  const splittedCapacityPerYears = sanitizeValue(
    String(capacityPerYears)
  ).split('#');
  const splittedUnits = sanitizeValue(units).split('#');
  const splittedPricePerUnits = sanitizeValue(
    String(pricePerUnits)
  ).split('#');

  const products: any[] = [];

  splittedNames.map((element: any, index: number) => {
    const capacityPerYear = Number(
      sanitezeNumberString(splittedCapacityPerYears[index])
    );
    const pricePerUnit = Number(
      sanitezeNumberString(splittedPricePerUnits[index])
    );
    const product = {
      name: splittedNames[index],
      capacityPerYear: capacityPerYear,
      capacityPerMonth: capacityPerYear / 12,
      pricePerUnit: pricePerUnit,
      productionValue: capacityPerYear * pricePerUnit,
      unit: splittedUnits[index]
    };

    products.push(product);
  });

  return products;
};

export const district = (value: any) => {
  if (!valueCheck(value)) return undefined;

  const dto = {
    district: value
  };
  const district = useUserModel().useFindDistrict(dto);

  return district;
};
